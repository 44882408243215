<template>
  <v-col class="cw-business-loan-terms-terms">
    <transition name="fade">
      <v-row>
        <v-col v-if="$wait.waiting('LIST_TERMS')" cols="12">
          <p><translate>Loading contracts</translate></p>
          <v-progress-linear
            color="primary"
            height="2"
            indeterminate
          />
        </v-col>
      </v-row>
    </transition>

    <transition name="fade">
      <div v-if="!$wait.waiting('LIST_TERMS')">
        <template v-for="(term, index) in sortedTerms">
          <div
            v-if="index === 0 || terms[index - 1].group !== term.group"
            :key="`${term.name}__title`"
            class="pt-4"
          >
            <h3
              class="
                text-body-2
                font-weight-bold
              "
            >
              {{ term.group }}
            </h3>
          </div>
          <div
            :key="`${term.name}__item`"
          >
            <cw-checkbox
              v-if="term.required"
              :id="term.name"
              v-model="term.value"
              class="my-0"
              rules="required"
              hide-details
              prevent-set-data
            >
              <template #label>
                <span class="mr-1" data-test>
                  Hyväksyn
                </span>
                <a
                  :id="`${term.name}--link`"
                  :href="termsLink(term.name)"
                  target="_blank"
                  @click.stop="$eventLogger.clickEvent($event)"
                >{{ term.displayName }}</a>
              </template>
            </cw-checkbox>
            <div v-else class="mt-4">
              <a
                :id="`${term.name}--link`"
                :href="termsLink(term.name)"
                target="_blank"
                @click.stop="$eventLogger.clickEvent($event)"
              >{{ term.displayName }}</a>
            </div>
          </div>
        </template>
      </div>
    </transition>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwBusinessLoanTermsTerms',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
  },

  computed: {
    ...mapGetters({
      terms: 'application/getTerms',
    }),

    sortedTerms() {
      const { $filters: { sortBy }, terms } = this;

      if (!terms.length) return [];

      return sortBy(terms, { key: 'group' });
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions({
      listTerms: 'application/listTerms',
    }),

    async init() {
      const {
        $wait,
        listTerms,
      } = this;

      $wait.start('LIST_TERMS');

      try {
        await listTerms();
      } catch (e) {} // eslint-disable-line

      $wait.end('LIST_TERMS');
    },

    termsLink(termName) {
      const { hashId } = this.$store.state.route.params;
      return `${process.env.VUE_APP_API_BASE_URL}/appTerms/viewTermsPdf/${hashId}/${termName}`;
    },
  },
};
</script>

<style lang="scss">
.cw-business-loan-terms-terms {
  &__info-text {
    margin-bottom: 16px !important;

    @include mq($from: md) {
      margin-top: 16px !important;
      margin-bottom: 16px !important;
    }
  }
}
</style>
